import { useGameContext } from "contexts";
import QRCode from "react-qr-code";

export const KioskThankYou = () => {
  const { endTime } = useGameContext();

  return (
    <div className="relative">
      <div className="text-[180px] text-white text-GT-Walsheim-Bold leading-none mt-24">
        {endTime}
      </div>
      <div className="text-[#002d73] text-GT-Walsheim-Bold text-[92px] leading-tight mb-8">
        Well done!
      </div>
      <div className="text-white text-GT-Walsheim-Regular text-[70px] leading-tight">
        Speak to a <br />
        Lucky Mobile
        <br />
        representative <br />
        for a chance to <br />
        win a new phone.
      </div>

      <div className="absolute top-[50px] -right-[350px]">
        <img src={require("assets/images/ThankYou.png")} alt="Thank You" />
      </div>

      <div className="flex items-center mt-32">
        <div className="p-4 rounded-xl mr-8">
          <QRCode
            size={240}
            value={process.env.REACT_APP_TY_QR_VALUE}
            viewBox={`0 0 240 240`}
            bgColor="#43b5e7"
            fgColor="#ffffff"
          />
        </div>

        <div className="text-white text-GT-Walsheim-Regular text-[70px] leading-tight">
          Or scan for your
          <br />
          chance to win
        </div>
      </div>
    </div>
  );
};
