import { Clock, GameTable } from "components";
import { useGameContext } from "contexts";
import { Fragment } from "react";

export const KioskGame = () => {
  const { isStarted } = useGameContext();

  return (
    <Fragment>
      <div className="mt-8 mb-16">
        <Clock start={isStarted} />
      </div>

      <GameTable />
    </Fragment>
  );
};
