export const KioskHowToPlay = () => {
  return (
    <div className="relative pt-40">
      <div className="text-white text-GT-Walsheim-Bold text-[80px] mb-8 leading-tight">
        How to Play
      </div>
      <div className="text-white text-GT-Walsheim-Medium text-[70px] mb-8 leading-tight">
        Tap on cards to
        <br />
        reveal them.
      </div>
      <div className="text-white text-GT-Walsheim-Medium text-[70px] leading-tight">
        Match the
        <br />
        pairs to win.
      </div>

      <div className="absolute top-[200px] -right-[350px]">
        <img src={require("assets/images/HowToPlay.png")} alt="How To Play" />
      </div>
    </div>
  );
};
