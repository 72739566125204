import { MobileLayout } from "components";

export const MobileDisconnected = () => {
  return (
    <MobileLayout>
      <div className="text-[#002d73] text-GT-Walsheim-Bold text-[32px] leading-tight mb-4 mt-12">
        Disconnected
      </div>

      <div className="text-white text-GT-Walsheim-Regular text-[24px] leading-tight">
        Speak to a <br />
        Lucky Mobile
        <br />
        representative <br />
        for a chance to <br />
        win a new phone.
      </div>
    </MobileLayout>
  );
};
