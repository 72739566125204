import { MobileLayout } from "components";
import { useGameContext } from "contexts";

export const MobileThankYou = () => {
  const { endTime } = useGameContext();

  return (
    <MobileLayout>
      <div className="relative">
        <div className="text-[60px] text-white text-GT-Walsheim-Bold leading-none mt-12">
          {endTime}
        </div>
        <div className="text-[#002d73] text-GT-Walsheim-Bold text-[32px] leading-tight mb-4">
          Well done!
        </div>
        <div className="text-white text-GT-Walsheim-Regular text-[24px] leading-tight">
          Speak to a <br />
          Lucky Mobile
          <br />
          representative <br />
          for a chance to <br />
          win a new phone.
        </div>

        <div className="absolute top-[20px] -right-[150px]">
          <img
            className="w-[300px] h-auto"
            src={require("assets/images/ThankYou.png")}
            alt="Thank You"
          />
        </div>
      </div>
    </MobileLayout>
  );
};
