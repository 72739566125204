import { GameProvider, MobileProvider, useMobileContext } from "contexts";
import { Fragment } from "react";
import {
  MobileDisconnected,
  MobileGame,
  MobileHowToPlay,
  MobileThankYou,
} from "screens";
import { MOBILE_SCREEN } from "utils";

const MobileScreen = () => {
  const { screen } = useMobileContext();

  if (screen === MOBILE_SCREEN.HOW_TO_PLAY) return <MobileHowToPlay />;

  if (screen === MOBILE_SCREEN.GAME) return <MobileGame />;

  if (screen === MOBILE_SCREEN.THANK_YOU) return <MobileThankYou />;

  if (screen === MOBILE_SCREEN.DISCONNECTED) return <MobileDisconnected />;

  return <Fragment />;
};

export const CloudRoutes = () => {
  return (
    <GameProvider>
      <MobileProvider>
        <MobileScreen />
      </MobileProvider>
    </GameProvider>
  );
};
