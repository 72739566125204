import { MobileLayout, Clock, GameTable } from "components";
import { useGameContext } from "contexts";

export const MobileGame = () => {
  const { isStarted, setEndTime } = useGameContext();

  return (
    <MobileLayout>
      <div className="mb-4">
        <Clock start={isStarted} onUpdateTime={setEndTime} />
      </div>

      <GameTable />
    </MobileLayout>
  );
};
