import clsx from "clsx";
import { useGameContext } from "contexts";
import { useMobileDevice } from "hooks";
import { styled } from "styled-components";

const CardWrapper = styled("div")(() => ({
  position: "relative",
  transition: "transform 0.3s",
  transformStyle: "preserve-3d",

  "&.flip": { transform: "perspective(500px) rotateY(180deg)" },
}));

const CardInner = styled("div")(() => ({
  position: "absolute",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  width: "100%",
  height: "100%",
  display: "flex",
  "-webkit-backface-visibility": "hidden",
  backfaceVisibility: "hidden",
}));

const CardFront = styled(CardInner)(() => ({
  transform: "rotateY(180deg)",
}));

const CardBack = styled(CardInner)(() => ({
  backgroundColor: "#fff",
}));

export const GameTable = () => {
  const isMobile = useMobileDevice();
  const { cardIds, flippedCardIds, openedCardIds, tapCard } = useGameContext();

  return (
    <div className="flex justify-center">
      <div className="flex flex-wrap -m-2">
        {cardIds.map((id) => {
          const isFlipped = flippedCardIds.includes(id);
          const isOpened = openedCardIds.includes(id);

          return (
            <div
              className={clsx(
                "flex justify-center w-3/12",
                isMobile ? "p-2" : "p-4"
              )}
              key={id}
            >
              <CardWrapper
                className={clsx(
                  isMobile ? "w-[72px] h-[128px]" : "w-[180px] h-[320px]",
                  isFlipped || isOpened ? "flip" : ""
                )}
              >
                <CardBack onClick={() => tapCard(id)}>
                  <img
                    className="w-full h-auto"
                    src={require("assets/images/card-back.png")}
                    alt="Card Back"
                  />
                </CardBack>

                <CardFront>
                  <img
                    className="w-full h-auto"
                    src={require(`assets/images/card-${
                      Math.floor(id / 2) % 6
                    }.png`)}
                    alt="Card Front"
                  />
                </CardFront>
              </CardWrapper>
            </div>
          );
        })}
      </div>
    </div>
  );
};
