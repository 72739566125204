import { useKioskContext } from "contexts";
import { Fragment } from "react";
import QRCode from "react-qr-code";

export const KioskHome = () => {
  const { sessionId } = useKioskContext();

  return (
    <Fragment>
      <div className="flex items-center justify-center">
        <video autoPlay loop muted>
          <source
            src={require("assets/videos/intro.mp4")}
            type="video/mp4"
          />
        </video>
      </div>

      <div className="absolute left-0 right-0 bottom-0 flex items-center justify-center">
        <div className="mb-10 p-4 bg-white">
          <QRCode
            size={320}
            value={`${window.location.origin}/kiosk-game?sessionId=${sessionId}`}
            viewBox={`0 0 320 320`}
          />
        </div>
      </div>
    </Fragment>
  );
};
